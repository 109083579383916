<script setup>
import { ref, watch, onBeforeMount } from 'vue';
import { useClientStore } from '@/stores/client';
import { storeToRefs } from 'pinia';

import ClientProfileTab from '../../components/Client/ClientProfileTab.vue';
import ClientIntakeTourTab from '../../components/Client/ClientIntakeTourTab.vue';
import ClientApplicationTab from '../../components/Client/ClientApplicationTab.vue';
import ClientSupportPlanTab from '../../components/Client/ClientSupportPlanTab.vue';

const { active } = storeToRefs(useClientStore());

import TimelineViewer from '@/components/Timeline/TimelineViewer.vue';

const tab = ref();

// If an intake tour is complete, have support plan as the default selected tab
watch(() => active.value, (client) => {
    if (client && client.intake_tour && client.intake_tour.status) {
        if (client.intake_tour.status.name == 'Completed') {
            tab.value = 'support_plan';
        } else {
            tab.value = 'intake_tour';
        }
    }
}, { immediate: true });

onBeforeMount(() => {
    active.value = {};
});
</script>

<template>
<MainContentLayout>
    <template #breadcrumb>
        <BreadCrumb :index-override="[0,2]" />
    </template>
    <template #title>{{ $t('label.client_details', { name: `${active.first_name} ${active.last_name}` ?? '' }) }}</template>
    <template #actions />
    <div>
        <v-card>
            <v-tabs v-model="tab">
                <v-tab value="support_plan">{{ $t('label.support_plan') }}</v-tab>
                <v-tab value="intake_tour">{{ $t('label.intake_tour') }}</v-tab>
                <v-tab value="profile">{{ $t('label.profile') }}</v-tab>
                <v-tab value="application">{{ $t('label.application') }}</v-tab>
                <v-tab value="timeline">{{ $t('label.timeline') }}</v-tab>
            </v-tabs>
            <v-card-text>
                <v-tabs-window v-model="tab">
                    <v-tabs-window-item value="support_plan">
                        <ClientSupportPlanTab :client="active" />
                    </v-tabs-window-item>
                    <v-tabs-window-item value="intake_tour" class="p-4">
                        <ClientIntakeTourTab :client="active" />
                    </v-tabs-window-item>
                    <v-tabs-window-item value="profile" class="p-4">
                        <ClientProfileTab :client="active" />
                    </v-tabs-window-item>
                    <v-tabs-window-item value="application">
                        <ClientApplicationTab :client="active" />
                    </v-tabs-window-item>
                    <v-tabs-window-item value="timeline">
                        <TimelineViewer :timelineable="active" type="client" />
                    </v-tabs-window-item>
                </v-tabs-window>
            </v-card-text>
        </v-card>
    </div>
</MainContentLayout>
</template>
