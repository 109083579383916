<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { ref } from 'vue';
import { useNoteStore } from '@/stores/note';

const props = defineProps({
    note: {
        type: Object,
        default: () => ({
            user_id: null,
            content: '',
        })
    },
    noteableType: {
        type: String,
        default: null
    },
    noteableId: {
        type: Number,
        default: null
    },
    mode: {
        type: String,
        default: 'Create'
    }
});

const emit = defineEmits(['close-modal']);
const { store, update } = useNoteStore();

const form = ref({ 
    ...props.note,
});

const save = async () => {
    const action = props.mode === 'Create' ? store : update;
    await action({
        ...form.value,
        noteable_type: props.noteableType,
        noteable_id: props.noteableId,
    });

    emit('close-modal');
};

const cancel = () => {
    emit('close-modal');
};
</script>

<template> 
<ModalWrapper> 
    <template #header>{{ mode }} {{ $t('label.note') }}</template>
    <template #subheader>{{ $t('label.create_or_edit', { 'what': 'note' }) }}</template>
    <form @submit.prevent="save"> 
        <div class="md:grid md:grid-cols-2 md:gap-4">
            <div class="text-lg font-bold">
                {{  $t('label.content') }}
            </div>
            <v-textarea
                v-model="form.content"
                class="col-span-2"
                :placeholder="$t('label.content')"
                outlined
                dense />
        </div>
    </form>
    <template #footer>
        <StyledSaveButton @save="save" @cancel="cancel" />
    </template>
</ModalWrapper>
</template>
