<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import NoteManager from '@/components/Notes/NoteManager.vue';
const emit = defineEmits(['close-modal']);

const props = defineProps({
    goal: {
        type: Object,
        default: null
    },
});

const cancel = () => {
    emit('close-modal');
};

</script>

<template>
<ModalWrapper>
    <template #header>{{ mode }} {{ $t('label.note') }}</template>
    <template #subheader>{{ $t('label.create_note') }}</template>

    <NoteManager
        type="goal"
        :noteable="props.goal" />

    <template #footer>
        <StyledButton
            variant="outlined"
            :label="$t('label.cancel')"
            @click="cancel">{{ $t('actions.cancel') }}</StyledButton>
    </template>
</ModalWrapper>
</template>
