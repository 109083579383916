<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { ref } from 'vue';
import { useClientStore } from '@/stores/client';
import { useOptionsStore } from '@/stores/options';

import GuardianSelector from '@/components/Client/GuardianSelector.vue';
import ContactSelector from '@/components/Client/ContactSelector.vue'; // Import the ContactSelector component

const { genderOptions } = useOptionsStore();
const { provinceOptions } = useOptionsStore();

const props = defineProps({
    program: {
        type: Object,
        default: () => ({
            'is_active': true,
        })
    },
    mode: {
        type: String,
        default: 'Create'
    }
});

const emit = defineEmits(['close-modal']);
const { store, update } = useClientStore();

const form = ref({ 
    is_independent_adult: false,
    is_active: true,
});

const guardians = ref([]);
const contacts = ref([]);

const save = async () => {
    const action = props.mode === 'Create' ? store : update;
    await action({
        ...form.value,
        guardians: guardians.value,
        contacts: contacts.value,
    });

    emit('close-modal');
};

const cancel = () => {
    emit('close-modal');
};

const updateGuardians = (updatedGuardians) => {
    guardians.value = updatedGuardians;
};

const updateContacts = (updatedContacts) => {
    contacts.value = updatedContacts;
};

</script>

<template>
<ModalWrapper>
    <template #header>{{ mode }} {{ $t('label.client') }}</template>
    <template #subheader>{{ $t('label.create_or_edit', { 'what': 'Client' }) }}</template>

    <form @submit.prevent="save">
        <div class="md:grid md:grid-cols-2 md:gap-4">
            <StyledInput
                v-model="form.first_name"
                name="first_name"
                :label="$t('label.first_name')"
                required
                :placeholder="$t('label.first_name')" />
            <StyledInput
                v-model="form.last_name"
                name="last_name"
                :label="$t('label.last_name')"
                required
                :placeholder="$t('label.last_name')" />   
            <StyledInput
                v-model="form.preferred_name"
                name="preferred_name"
                :label="$t('label.preferred_name')"
                :placeholder="$t('label.preferred_name')" />
            <StyledDatepicker   
                v-model="form.dob"
                :placeholder="$t('label.dob')"
                name="dob"
                :label="$t('label.dob')" />
            <StyledDropdown
                v-model="form.gender"
                :placeholder="$t('label.gender')"
                :options="genderOptions"
                name="gender"
                :label="$t('label.gender')"  />
            <StyledMask
                v-model="form.phone"
                mask="###-###-####"
                :placeholder="$t('label.phone')"
                name="phone"
                :label="$t('label.phone')" />
            <StyledInput
                v-model="form.email"
                :placeholder="$t('label.email')"
                name="email"
                type="email"
                :label="$t('label.email')" />
            <StyledInput
                v-model="form.band_number"
                name="band_number"
                :placeholder="$t('label.band_number')"
                :label="$t('label.band_number')" />
            <StyledInput
                v-model="form.treaty_number"
                name="treaty_number"
                type="text"
                :placeholder="$t('label.treaty_number')"
                :label="$t('label.treaty_number')" />
            <StyledTextArea
                v-model="form.medical"
                :placeholder="$t('label.medical')"
                name="medical"
                :label="$t('label.medical')" />
            <StyledTextArea
                v-model="form.allergies"
                :placeholder="$t('label.allergies')"
                name="allergies"
                :label="$t('label.allergies')" />
            <StyledTextArea
                v-model="form.behavioral_concerns"
                :placeholder="$t('label.behavioral_concerns')"
                name="behavioral_concerns"
                :label="$t('label.behavioral_concerns')" />
            <v-divider class="col-span-full my-2 md:my-4" />
            <div class="col-span-full mb-4">{{ $t('label.health_info') }}</div>
            <StyledMask
                v-model="form.sin"
                name="sin"
                :placeholder="$t('label.sin')"
                :options="{ mask: '###-###-###' }"
                :label="$t('label.sin')" />

            <StyledInput
                v-model="form.healthcare_number"
                name="healthcare_number"
                :placeholder="$t('label.healthcare_number')"
                :label="$t('label.healthcare_number')" />

            <StyledInput
                v-model="form.medical_services_number"
                name="medical_services_number"
                :placeholder="$t('label.medical_services_number')"
                :label="$t('label.medical_services_number')" />

            <v-divider class="col-span-full my-2 md:my-4" />
            <div class="col-span-full mb-4">{{ $t('label.address_info') }}</div>
            <StyledInput
                v-model="form.address"
                name="address"
                :label="$t('label.address')"
                :placeholder="$t('label.address')" />
            <StyledInput
                v-model="form.city"
                name="city"
                :label="$t('label.city')"
                :placeholder="$t('label.city')" />
            <StyledDropdown
                v-model="form.province"
                :placeholder="$t('label.province')"
                :options="provinceOptions"
                name="province"
                :label="$t('label.province')"  />
            <StyledMask
                v-model="form.postal_code"
                name="postal_code"
                :placeholder="$t('label.postal_code')"
                :options="{ mask: '@#@-#@#' }"
                :label="$t('label.postal_code')" />
            <StyledRoundSwitch
                v-model="form.is_active"
                color="primary"
                inset
                name="is_active"
                :label="$t('label.active')" />

            <v-divider class="col-span-full my-2 md:my-4" />
            <div class="col-span-full mb-4">{{ $t('label.guardianship') }}</div>

            <StyledCheckbox
                v-model="form.is_independent_adult"
                :label="$t('label.is_independent_adult')"
                name="is_independent_adult" />
                
            <div v-if="!form.is_independent_adult" class="col-span-full">
                <GuardianSelector 
                    @guardians-updated="updateGuardians" />
            </div>

            <v-divider class="col-span-full mb-4" />
            <div class="col-span-full mb-4">{{ $t('label.client_contacts') }}</div>

            <ContactSelector
                class="col-span-full"
                @contacts-updated="updateContacts" />
        </div>
    </form>

    <template #footer>
        <StyledSaveButton @save="save" @cancel="cancel" />
    </template>
</ModalWrapper>
</template>
