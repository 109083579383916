<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { ref } from 'vue';
import { useTimelineStore } from '@/stores/timeline';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';
import { useDateFormatter } from '@/composables/dateFormatter';
const { formatDate, parseLocalDateFromString } = useDateFormatter();

const props = defineProps({
    timeline: {
        type: Object,
        default: () => ({
            date: null,
            time: null,
            type: null,
            staff: [],
            programs: [],
            clients: []
        })
    },
    mode: {
        type: String,
        default: 'Create'
    },
    timelineableId: {
        type: Number,
        default: null
    },
    timelineableType: {
        type: String,
        default: null
    }
});

const emit = defineEmits(['close-modal']);
const { storeTimeline, updateTimeline } = useTimelineStore();
const { 
    timelineTypes, 
    timeOptions,
    activeStaffOptions,
    clientOptions,
    programOptions,
} = storeToRefs(useOptionsStore());

const form = ref({
    ...props.timeline,
});

if (props.mode != 'Create') {
    // Remap the type dropdown
    form.value.type = props.timeline.type_id;
    form.value.clients = props.timeline.clients.map(client => client.id);
    form.value.programs = props.timeline.programs.map(program => program.id);
    form.value.staff = props.timeline.staff.map(staff => staff.id);
    form.value.date = parseLocalDateFromString(props.timeline.date);
}

if (props.mode == 'Create') {
    // Pre-Select the Program/Client/Staff when creating
    if (props.timelineableType == 'client') {
        form.value.clients = [props.timelineableId];
    } else if (props.timelineableType == 'program') {
        form.value.programs = [props.timelineableId];
    } else if (props.timelineableType == 'staff') {
        form.value.staff = [props.timelineableId];
    }
}

const save = async () => {
    const action = props.mode === 'Create' ? storeTimeline : updateTimeline;

    await action({
        ...form.value,
        date: formatDate(form.value.date),
    });

    emit('close-modal');
};

const cancel = () => {
    emit('close-modal');
};
</script>

<template>
<ModalWrapper>
    <template #header>{{ mode }} {{ $t('label.event') }}</template>
    <template #subheader>{{ $t('label.create_or_edit', { 'what': 'Event' }) }}</template>

    <form @submit.prevent="save">
        <div class="md:grid md:grid-cols-3 gap-x-4">
            <StyledDatepicker
                v-model="form.date"
                :label="$t('label.date')"
                view-mode="month"
                name="date" />
            <StyledDropdown
                v-model="form.time"
                :options="timeOptions"
                :label="$t('label.time')"
                name="time" />
            <StyledDropdown
                v-model="form.type"
                clearable
                :placeholder="$t('label.type')"
                :options="timelineTypes"
                name="type" />
            <StyledDropdown
                v-model="form.clients"
                multiple
                searchable
                class="col-span-full"
                :options="clientOptions"
                :label="$t('label.clients_involved')"
                name="clients" />
            <StyledAutoComplete
                v-model="form.staff"
                multiple
                searchable
                class="col-span-full"
                :options="activeStaffOptions"
                :label="$t('label.staff_involved')"
                name="staff" />
            <StyledDropdown
                v-model="form.programs"
                multiple
                searchable
                class="col-span-full"
                :options="programOptions"
                :label="$t('label.programs_involved')"
                name="programs" />
        </div>
    </form>

    <template #footer>
        <StyledSaveButton @save="save" @cancel="cancel" />
    </template>
</ModalWrapper>
</template>
