<script setup>
import { useSupportPlanGoalEventStore } from '@/stores/supportPlanGoalEvent';
import { useSupportPlanGoalStore } from '@/stores/supportPlanGoal';
import { useClientStore } from '@/stores/client';
import { storeToRefs } from 'pinia';
import { router } from '@inertiajs/vue3';
import { inject, ref } from 'vue';

const { supportPlanGoalEvents } = storeToRefs(useSupportPlanGoalEventStore());
const { active } = storeToRefs(useSupportPlanGoalStore());
const { active: client } = storeToRefs(useClientStore());
const { searchSupportPlanGoalEvents } = useSupportPlanGoalEventStore();

const params = ref({
    term: '',
    page: 1,
    sort_by: null,
    per_page: 10,
    type: null
});

const searchEvents = () => {
    searchSupportPlanGoalEvents({
        ...params.value,
        hash: active.value.hash,
    });
};

const accessEvent = (event) => {
    router.visit(`/clients/support-plan/goals/events/${event.hash}/details`);
};

const getTrackingMetric = (event) => {
    if (active.value?.type?.name === 'Measurable Criteria') {
        return event.measurable_goal_criteria_value + ' ' + active.value.measurable_unit_name;
    }

    if (active.value?.type?.name === 'Defined Criteria') {
        return event.support_plan_defined_option.defined_criteria_name;
    }
    return 'N/A';
};

const modals = inject('modals');

const openGoalTrackingEventModal = (goalTrackingEvent = null) => {
    modals.show('GoalTrackingEventModal', {
        props: {
            mode: goalTrackingEvent ? 'Edit' : 'Create',
            event: goalTrackingEvent ? goalTrackingEvent : {},
            goal: active.value,
        }
    }).then(() => {
        searchEvents();
    });
};
</script>

<template>
<MainContentLayout>
    <template #breadcrumb><BreadCrumb /></template>
    <template #title>{{ $t('label.goal_tracking_events', { name: `${client.first_name} ${client.last_name}` ?? '', plan_type: `${active.support_plan?.type?.name}` ?? '', goal_description: active.description ?? '' }) }}</template>
    <div class="flex justify-end pb-4">
        <StyledButton @click="openGoalTrackingEventModal(null)">
            <FontAwesomeIcon icon="plus" class="mr-1" />{{ $t('label.new_tracking_event') }}
        </StyledButton>
    </div>

    <DataTable
        :data="supportPlanGoalEvents"
        :headers="[
            { title: $t('label.user'), key: 'user' },
            { title: $t('label.date_time'), key: 'date_time', value: item => `${item.date} ${item.time}` },
            { title: $t('label.description'), key: 'description', value: item => item.description },
            { title: $t('label.tracking_metric'), key: 'tracking_metric', value: item => getTrackingMetric(item) },
            { title: $t('label.attachments'), key: 'attachments', value: item => 'N/A' },
        ]"
        :actions="[
            {
                title: $t('label.access'),
                icon: 'mdi-arrow-right-circle-outline',
                action: (item) => accessEvent(item),
            },
        ]">

        <template #item.user = "{ item }"> <!-- eslint-disable-line vue/valid-v-slot -->
            <FontAwesomeIcon 
                v-if="item.was_breakthrough"
                icon="asterisk" />
            {{ client.first_name }} {{ client.last_name }}
        </template>
    </DataTable>
</MainContentLayout>
</template>
