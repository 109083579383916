<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { ref } from 'vue';
import { useRequirementStore } from '@/stores/requirement';
import { useOptionsStore } from '@/stores/options';

const props = defineProps({
    requirement: {
        type: Object,
        default: () => ({
            'required_for_staff': true,
            'is_active': true,
        })
    },
    mode: {
        type: String,
        default: 'Create'
    }
});

const emit = defineEmits(['close-modal']);
const { store, update } = useRequirementStore();

const { requirementTypes } = useOptionsStore();

const form = ref({
    ...props.requirement,
    type: requirementTypes.find(type => type.value === props?.requirement?.type?.id)?.value || null,
});

const save = async () => {
    const action = props.mode === 'Create' ? store : update;
    await action({
        ...form.value,
    });

    emit('close-modal');
};

const cancel = () => {
    emit('close-modal');
};
</script>

<template>
<ModalWrapper>
    <template #header>{{ mode }} {{ $t('label.requirement') }}</template>
    <template #subheader>{{ $t('label.create_or_edit', { 'what': 'Requirement' }) }}</template>

    <form @submit.prevent="save">
        <div class="md:grid md:grid-cols-2 gap-4">
            <StyledDropdown
                v-model="form.type"
                class="col-span-full"
                :placeholder="$t('label.type')"
                :options="requirementTypes"
                name="type"
                :label="$t('label.type')" />
            <StyledRoundSwitch
                v-model="form.can_expire"
                color="primary"
                inset
                name="can_expire"
                :label="$t('label.can_expire')" />
            <StyledInput
                v-model="form.days_to_expire"
                :disabled="!form.can_expire"
                :placeholder="$t('label.days_to_expire')"
                name="days_to_expire"
                type="number"
                :label="$t('label.days_to_expire')" />
            <StyledRoundSwitch
                v-model="form.required_for_staff"
                color="primary"
                inset
                name="is_active"
                :label="$t('label.required_for_staff')" /> 
            <StyledRoundSwitch
                v-model="form.is_active"
                color="primary"
                inset
                name="is_active"
                :label="$t('label.active')" />
        </div>
    </form>

    <template #footer>
        <StyledSaveButton @save="save" @cancel="cancel" />
    </template>
</ModalWrapper>
</template>
