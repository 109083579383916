<script setup>
import ModalWrapper from '@/modals/ModalWrapper';

defineProps({
    title: {
        type: String,
        default: 'Confirmation'
    },

    prompt: {
        type: String,
        default: 'Confirmation'
    }
});

const emit = defineEmits(['close-modal']);

const confirmModal = () => {
    emit('close-modal', true);
};

const cancel = () => {
    emit('close-modal', false);
};
</script>

<template>
<ModalWrapper body-class="modal-default-content" :fixed-height="false">
    <template #header>{{ title }}</template>
    <template #subheader>{{ prompt }}</template>

    <template #footer>
        <StyledButton
            variant="outlined"
            :label="$t('label.cancel')"
            @click="cancel">{{ $t('actions.cancel') }}</StyledButton>
        <StyledButton @click="confirmModal">{{ $t('actions.confirm') }}</StyledButton>
    </template>
</ModalWrapper>
</template>

