<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { watch, ref } from 'vue';
import { storeToRefs } from 'pinia';

import { useSupportPlanStore } from '@/stores/supportPlan';
import { useOptionsStore } from '@/stores/options';
import { useDateFormatter } from '@/composables/dateFormatter';

const emit = defineEmits(['close-modal']);

const { store, update } = useSupportPlanStore();
const { supportPlanStatusOptions } = storeToRefs(useOptionsStore()); 
const { supportPlanTypeOptions } = storeToRefs(useOptionsStore()); 

const { parseDate } = useDateFormatter();

const props = defineProps({
    plan: {
        type: Object,
        default: () => ({
            start_date: '',
            review_date: '',
            status: '',
            type: ''
        })
    },
    client: {
        type: Object,
        required: true
    },
    mode: {
        type: String,
        default: 'Create'
    }
});

const form = ref({
    ...props.plan,
});

watch(() => props.plan, (newPlan) => {
    if (props.mode === 'Create' || !newPlan) {
        form.value = {
            client_id: props.client.id,
            start_date: new Date(),
            review_date: new Date(),
            status_id: '',
            type_id: ''
        };
    } else {
        form.value = {
            client_id: props.client.id,
            plan_id: newPlan.id, 
            start_date: parseDate(newPlan.start_date.split('T')[0]) || '',
            review_date: parseDate(newPlan.review_date.split('T')[0]) || '',
            status_id: newPlan.status_id || '',
            type_id: newPlan.type_id || ''
        };
    }
}, { immediate: true });

const save = async () => {
    const action = props.mode === 'Create' ? store : update;

    await action({
        hash: props?.plan?.hash ?? null,
        ...form.value
    });

    emit('close-modal');
};

const cancel = () => {
    emit('close-modal');
};
</script>

<template>
<ModalWrapper>
    <template #header>{{ $t('label.modal_title', { mode: mode, what: 'Support Plan'}) }}</template>
    <template #subheader>{{ $t('label.create_or_edit', { what: 'Support Plan'}) }}</template>

    <form @submit.prevent="save">
        <div class="md:grid md:grid-cols-2 md:gap-4">
            <StyledDatepicker
                v-model="form.start_date"
                :placeholder="$t('label.start_date')"
                :clearable="false"
                name="start_date"
                :label="$t('label.start_date')" />

            <StyledDatepicker
                v-model="form.review_date"
                :placeholder="$t('label.review_date')"
                :clearable="false"
                name="review_date"
                :label="$t('label.review_date')" />

            <StyledDropdown
                v-model="form.status_id"
                :options="supportPlanStatusOptions"
                :placeholder="$t('label.status')"
                name="status"
                :label="$t('label.status')" />
            
            <StyledDropdown
                v-model="form.type_id"
                :options="supportPlanTypeOptions"
                :placeholder="$t('label.type')"
                name="type"
                :label="$t('label.type')" />
        </div>
    </form>

    <template #footer>
        <StyledSaveButton @save="save" @cancel="cancel" />
    </template>
</ModalWrapper>
</template>
