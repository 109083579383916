<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { ref } from 'vue';
import { storeToRefs } from 'pinia';

import { useSupportPlanGoalStore } from '@/stores/supportPlanGoal';
import { useOptionsStore } from '@/stores/options';

const emit = defineEmits(['close-modal']);

const { storeSupportPlanGoal, updateSupportPlanGoal } = useSupportPlanGoalStore();
const { responsiblePartyOptions, supportPlanGoalTypes, supportPlanGoalOutcomes } = storeToRefs(useOptionsStore());

const props = defineProps({
    goal: {
        type: Object,
        default: () => ({})
    },

    supportPlan: {
        type: Object,
        required: true
    },
    mode: {
        type: String,
        default: 'Create'
    }
});

const form = ref({
    ...props.goal,
    support_plan_id: props.supportPlan.id,
    managers: (props.goal.managers || []).map((manager) => manager.id),
    defined_options: props.goal.defined_options || [],
    outcome: props.goal.outcome ? props.goal.outcome.id : null,
});

const save = async () => {
    const action = props.mode === 'Create' ? storeSupportPlanGoal : updateSupportPlanGoal;

    await action({
        ...form.value,
        support_plan_type_id: props.supportPlan.type.id,
    });

    emit('close-modal');
};

const cancel= () => {
    emit('close-modal');
};

const goalIsTypeOfType = (type) => {
    return supportPlanGoalTypes.value.some((goalType) => {
        return goalType.label === type && form.value.type_id === goalType.value;
    });
};

const addDefinedCriteria = () => {
    form.value.defined_options.push({
        'defined_criteria_name': '',
    });
};

const removeOption = (option) => {
    const index = form.value.defined_options.indexOf(option);
    form.value.defined_options.splice(index, 1);
};
</script>

<template>
<ModalWrapper>
    <template #header>{{ $t('label.modal_title', { mode: mode, what: 'Goal'}) }}</template>
    <template #subheader>{{ $t('label.create_or_edit', { what: 'the goal of current support plan'}) }}</template>
    <form @submit.prevent="save">
        <div class="md:grid md:grid-cols-2 md:gap-4">
            <StyledInput
                v-model="form.description"
                :placeholder="$t('label.goal_description')"
                name="description"
                :label="$t('label.goal')" />
            <!-- WSP does not need outcome within goal creation -->
            <StyledDropdown
                v-if="supportPlan.type.name == 'ISP (Individual Support Plan)'"
                v-model="form.outcome"
                :placeholder="$t('label.outcome')"
                name="outcome"
                :options="supportPlanGoalOutcomes"
                :label="$t('label.outcome')" />
            <StyledTextArea
                v-model="form.staff_support_strategies_and_objectives"
                class="col-span-full"
                :placeholder="$t('label.staff_support_strategies_and_objectives')"
                name="staff_support_strategies_and_objectives"
                :label="$t('label.staff_support_strategies_and_objectives')" />
            <StyledAutoComplete
                v-if="supportPlan.type.name == 'ISP (Individual Support Plan)'"
                v-model="form.managers"
                :options="responsiblePartyOptions"
                name="managers"
                :searchable="true"
                :clearable="true"
                :multiple="true"
                :label="$t('label.responsible_party')"
                :placeholder="$t('label.select_responsible_party')" />
            <StyledDropdown
                v-model="form.type_id"
                :options="supportPlanGoalTypes"
                :placeholder="$t('label.success_criteria')"
                name="type_id"
                :label="$t('label.success_criteria')" />
            <!-- If it is measurable success criteria -->
            <StyledInput
                v-if="goalIsTypeOfType('Measurable Criteria')"
                v-model="form.measurable_unit_name"
                :placeholder="$t('label.unit_name')"
                name="measurable_unit_name"
                :label="$t('label.unit_name')" />
            <StyledInput
                v-if="goalIsTypeOfType('Measurable Criteria')"
                v-model="form.measurable_unit_target"
                :placeholder="$t('label.target')"
                name="measurable_unit_target"
                :label="$t('label.target')" />
            <div v-if="goalIsTypeOfType('Defined Criteria')" class="col-span-full"> 
                <div 
                    v-for="opt in form.defined_options"
                    :key="`option_${opt.id}`"
                    class="grid grid-cols-12 gap-4">
                    <StyledInput
                        v-model="opt.defined_criteria_name"
                        class="col-span-8"
                        :placeholder="$t('label.criteria_name')"
                        name="defined_criteria_name"
                        :label="$t('label.criteria_name')" />
                    <div class="flex gap-2 text-red-500 col-span-2 mt-2 cursor-pointer hover:underline">
                        <v-icon icon="mdi-trash-can-outline" @click="removeOption(opt)" />
                    </div>
                </div>
            </div>
            <!-- If it is defined success criteria -->
            <StyledButton v-if="goalIsTypeOfType('Defined Criteria')" class="w-60" @click="addDefinedCriteria()">
                <div class="flex items-center justify-center">
                    <v-icon class="mr-2" icon="mdi-plus" />
                    {{ $t('label.add_defined_criteria') }}
                </div>
            </StyledButton>
            <StyledTextArea
                v-model="form.comments"
                class="col-span-full"
                :placeholder="$t('label.comments')"
                name="comments"
                :label="$t('label.comments')" />
        </div>
    </form>
    <template #footer>
        <StyledSaveButton @save="save" @cancel="cancel" />
    </template>
</ModalWrapper>
</template>
