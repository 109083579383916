<script setup>
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import { useSupportPlanGoalStore } from '@/stores/supportPlanGoal';
import { router } from '@inertiajs/vue3';

const { searchSupportPlanGoals } = useSupportPlanGoalStore();
const { supportPlanGoals } = storeToRefs(useSupportPlanGoalStore());

import { inject } from 'vue';
import { trans } from 'laravel-vue-i18n';
const modals = inject('modals');

const props = defineProps({
    supportPlan: {
        type: Object,
        default: () => null
    }
});

const params = ref({
    term: '',
    page: 1,
    per_page: 10,
});

const fetchSupportPlanGoals = () => {
    searchSupportPlanGoals({
        ...params.value,
        hash: props.supportPlan.hash,
    });
};

const openSupportPlanGoalModal = (supportPlanGoal = null) => {
    modals.show('SupportPlanGoalModal', {
        props: {
            mode: supportPlanGoal ? 'Edit' : 'Create',
            goal: supportPlanGoal ? supportPlanGoal : {},
            supportPlan: props.supportPlan,
        }
    }).then(() => {
        fetchSupportPlanGoals();
    });
};

const tableUpdate = ({ page, sortBy, itemsPerPage }) => {
    Object.assign(params.value, { page, sort_by: sortBy, per_page: itemsPerPage });
    fetchSupportPlanGoals();
};

const accessGoalTrackingEvents = (goal) => {
    router.visit(`/clients/support-plan/goals/${goal.hash}/events`);
};

const accessViewNotesModal = (goal) => {
    modals.show('SupportPlanGoalNotesModal', {
        props: {
            goal: goal,
        }
    });
};

const goalHeaders = computed(() => {
    const headers = [
        { title: trans('label.description'), key: 'description', value: item => `${item.description}` },
        { title: trans('label.staff_support_strategies_and_objectives'), key: 'staff_support_strategies_and_objectives', value: item => `${item.staff_support_strategies_and_objectives}` },
        { title: trans('label.responsible'), key: 'responsible', value: item => item.managers.map(manager => manager.name).join(', ') },
    ];

    // Only ISP support plan has outcomes
    if (props.supportPlan.type.name === 'ISP (Individual Support Plan)') {
        headers.splice(1, 0, {
            title: trans('label.outcomes'),
            key: 'outcome',
            value: item => `${item.outcome ? item.outcome.name : 'N/A'}`,
        });
    }

    return headers;
});

</script>

<template>
<div>
    <div class="flex justify-end pb-4">
        <StyledButton @click="openSupportPlanGoalModal(null)">
            <FontAwesomeIcon icon="plus" class="mr-1" />{{ $t('label.new_goal') }}
        </StyledButton>
    </div>
    <DataTable
        v-if="supportPlanGoals && supportPlanGoals.data"
        :data="supportPlanGoals"
        :headers="goalHeaders"
        :actions="[
            {
                title: $t('label.edit'),
                icon: 'mdi-pencil',
                action: (item) => openSupportPlanGoalModal(item),
            },
            {
                title: $t('label.events'),
                icon: 'mdi-arrow-right-circle-outline',
                action: (item) => accessGoalTrackingEvents(item),
            },
            {
                title: $t('label.view_notes'),
                icon: 'mdi-note-text',
                action: (item) => accessViewNotesModal(item),
            },
            
        ]" 
        @table-update="tableUpdate" />
    <div v-else>
        {{ $t('label.no_support_plan_goals') }}
    </div>
</div>
</template>
