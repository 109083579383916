<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { ref } from 'vue';
import { useStaffStore } from '@/stores/staff';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';

const props = defineProps({
    staff: {
        type: Object,
        default: () => ({
            'first_name': '',
            'last_name': '',
            'phone': '',
            'dob': new Date(),
            'emerg_first_name': '',
            'emerg_last_name': '',
            'emerg_phone': '',
            'emerg_email': '',
            
            // user related 
            'email': '',
            'password': '',
            'password_confirmation': '',
            'role': null
        })
    },
    mode: {
        type: String,
        default: 'Create'
    }
});

const emit = defineEmits(['close-modal']);
const { store, update } = useStaffStore();

const { roles } = storeToRefs(useOptionsStore());

const form = ref({
    ...props.staff,
});

const save = async () => {
    const action = props.mode === 'Create' ? store : update;
    await action({
        ...form.value,
    });

    emit('close-modal');
};

const cancel = () => {
    emit('close-modal');
};
</script>

<template>
<ModalWrapper>
    <template #header>{{ $t('label.modal_title', { mode: mode, what: 'Staff'}) }}</template>
    <template #subheader>{{ $t('label.create', { what: 'Staff'}) }}</template>

    <form @submit.prevent="save">
        <div class="md:grid md:grid-cols-2 gap-x-4 gap-y-2">
            <StyledInput
                v-model="form.first_name"
                :placeholder="$t('label.first_name')"
                name="first_name"
                :label="$t('label.first_name')" />
            <StyledInput
                v-model="form.last_name"
                :placeholder="$t('label.last_name')"
                name="last_name"
                :label="$t('label.last_name')" />
            <StyledMask
                v-model="form.phone"
                mask="###-###-####"
                :placeholder="$t('label.phone')"
                name="phone"
                :label="$t('label.phone')" />
            <StyledDatepicker
                v-model="form.dob"
                :placeholder="$t('label.dob')"
                name="dob"
                :range="false"
                :label="$t('label.dob')" />
            <StyledInput
                v-model="form.email"
                :placeholder="$t('label.email')"
                name="email"
                type="email"
                :label="$t('label.email')" />
            <StyledPassword
                v-model="form.password"
                :placeholder="$t('label.password')"
                name="password"
                :label="$t('label.password')" />
            <StyledPassword
                v-model="form.password_confirmation"
                :placeholder="$t('label.password_confirmation')"
                name="password_confirmation"
                :label="$t('label.password_confirmation')" />
            <StyledDropdown
                v-model="form.role"
                :placeholder="$t('label.role')"
                :options="roles"
                name="role"
                :label="$t('label.role')" />
            <v-divider class="col-span-full my-4" />
            <div class="col-span-full mb-4">{{ $t('label.emerg_info') }}</div>
            <StyledInput
                v-model="form.emerg_first_name"
                :placeholder="$t('label.first_name')"
                name="emerg_first_name"
                :label="$t('label.first_name')" />
            <StyledInput
                v-model="form.emerg_last_name"
                :placeholder="$t('label.last_name')"
                name="emerg_last_name"
                :label="$t('label.last_name')" />
            <StyledMask
                v-model="form.emerg_phone"
                mask="###-###-####"
                :placeholder="$t('label.phone')"
                name="emerg_phone"
                :label="$t('label.phone')" />
            <StyledInput
                v-model="form.emerg_email"
                :placeholder="$t('label.email')"
                name="emerg_email"
                :label="$t('label.email')" />
        </div>
    </form>

    <template #footer>
        <StyledSaveButton @save="save" @cancel="cancel" />
    </template>
</ModalWrapper>
</template>
