<script setup>
import ModalWrapper from '@/modals/ModalWrapper';
import { watch, toRefs, ref, computed } from 'vue';
import { useStaffStore } from '@/stores/staff';
import { useOptionsStore } from '@/stores/options';
import { storeToRefs } from 'pinia';
import { useDateFormatter } from '@/composables/dateFormatter';

const emit = defineEmits(['close-modal']);
const { storeStaffRequirement, updateStaffRequirement } = useStaffStore();
const { requirementOptions } = storeToRefs(useOptionsStore());
const { formatDate, parseLocalDateFromString } = useDateFormatter();

const props = defineProps({
    staffRequirement: {
        type: Object,
        default: () => ({
            //
        })
    },
    staff: {
        type: Object,
        required: true
    },
    mode: {
        type: String,
        default: 'Create'
    }
});

const { staffRequirement } = toRefs(props);
const { staff } = toRefs(props);

const form = ref({
    //
});

watch(() => staffRequirement, (staffRequirement) => {
    if (props.mode === 'Create') {
        return;
    }

    form.value = {
        requirement: requirementOptions.value.find(req => req.value == staffRequirement.value.pivot.requirement_id).value,
        expires_on: staffRequirement.value.pivot?.expires_on ? parseLocalDateFromString(staffRequirement.value.pivot.expires_on) : null,
        issue_date: staffRequirement.value.pivot?.issue_date ? parseLocalDateFromString(staffRequirement.value.pivot.issue_date) : null,
        notes: staffRequirement.value.pivot?.notes ?? '',
        has_file: staffRequirement.value.pivot?.has_file,
    };
}, { immediate: true });

const canExpire = computed(() => {
    const selectedRequirement = requirementOptions.value.find(req => req.value == form.value.requirement);
    return selectedRequirement ? selectedRequirement.can_expire : false;
});

const save = async () => {
    const action = props.mode === 'Create' ? storeStaffRequirement : updateStaffRequirement;

    const formData = new FormData();
    formData.append('staff_hash', staff?.value?.hash);
    formData.append('requirement_hash', staffRequirement?.value?.hash); // for updating, this is the hash of the requirement (for route-model binding)
    formData.append('requirement', form.value?.requirement); // for create, this is the ID of the requirement
    formData.append('expires_on', form.value.expires_on ? formatDate(form.value.expires_on) : null);
    formData.append('can_expire', requirementOptions.value.find(req => req.value == form.value.requirement).can_expire);
    formData.append('issue_date', form.value.issue_date ? formatDate(form.value.issue_date) : null);
    formData.append('notes', form.value.notes ?? '');
    if (form.value.file) {
        formData.append('file', form.value.file);
    }

    await action(formData);

    emit('close-modal');
};

const cancel = () => {
    emit('close-modal');
};

const downloadFile = () => {
    const staffHash = staff.value.hash;
    const requirementId = staffRequirement.value.pivot.id;
    window.location.href = `/api/v1/staff/${staffHash}/requirements/${requirementId}/download`;
};

</script>

<template>
<ModalWrapper>
    <template #header>{{ $t('label.modal_title', { mode: mode, what: 'Staff Training'}) }}</template>
    <template #subheader>{{ $t('label.create_or_edit', { what: 'Staff Training'}) }}</template>

    <form @submit.prevent="save">
        <div class="md:grid md:grid-cols-3 gap-x-4">
            <StyledDropdown
                v-model="form.requirement"
                :disabled="mode === 'Edit'"
                :placeholder="$t('label.requirement_options')"
                :options="requirementOptions"
                name="requirement"
                :label="$t('label.requirement_options')" />
            <StyledDatepicker   
                v-model="form.issue_date"
                :placeholder="$t('label.issue_date')"
                name="issue_date"
                :label="$t('label.issue_date')" />
            <StyledDatepicker
                v-model="form.expires_on"
                :disabled="!canExpire"
                :placeholder="$t('label.expires_on')"
                name="expires_on"
                :label="$t('label.expires_on')" />
            <StyledTextArea
                v-model="form.notes"
                class="col-span-full"
                :placeholder="$t('label.notes')"
                name="notes"
                :label="$t('label.notes')" />
            <StyledFileSelector
                v-model="form.file"
                name="file"
                class="col-span-full"
                :label="$t('label.file_selector')" />
            <div v-if="form.file && form.has_file" class="col-span-full flex gap-1">
                <v-icon 
                    icon="mdi-alert-circle-outline"
                    color="red"
                    class="self-center" />
                <div class="self-center text-red-500">{{ $t('label.file_replace_warning') }} </div>
            </div>
            <div v-if="form.has_file" class="col-span-full">
                <StyledButton @click.prevent="() => downloadFile()">
                    {{ $t('label.download_file') }}
                </StyledButton>
            </div>
        </div>
    </form>
    <template #footer>
        <StyledSaveButton @save="save" @cancel="cancel" />
    </template>
</ModalWrapper>
</template>
